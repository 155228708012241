import "assets/sass/main.scss";

import { Theme, ThemeContext } from "assets";
import { LogoIntro } from "atoms";
import { graphql, useStaticQuery } from "gatsby";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const InternalHomePage = (props) => {
  const homeData = props?.craftAPI?.entry;

  const children = convertComponentList(homeData?.componentList);

  children.unshift(<LogoIntro key="logo-intro" />);

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout theme={Theme.Black} title={homeData.title}>
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

InternalHomePage.propTypes = {
  craftAPI: PropTypes.shape({
    entry: PropTypes.shape({}),
  }),
};

const HomePage = (props) => {
  const homeQuery = useStaticQuery(graphql`
    query {
      craftAPI {
        entry(type: "homepage") {
          id
          title
          uri
          ... on CraftAPI_homepage_homepage_Entry {
            componentList {
              ...ComponentListFragment
            }
          }
        }
      }
    }
  `);

  return <InternalHomePage {...homeQuery} {...props} />;
};

export default HomePage;
